<template>
  <div class="app_con">

    <el-button size="small" type="primary" icon="el-icon-back" class="m-b-10" @click="jumpToList">返回</el-button>


    <el-descriptions :column="4" labelClassName="desLabel" contentClassName="desContent" border>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          订单号
        </template>
        {{ detail.id }}
      </el-descriptions-item>
      <el-descriptions-item :contentStyle="{ width: '100px' }">
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          状态
        </template>
        {{ detail.orderStatus | filterOrderStatus }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-location-outline"></i>
          下单时间
        </template>
        {{ detail.createtime }}
      </el-descriptions-item>

      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-location-outline"></i>
          订单类型
        </template>
        {{ detail.orderType | filterOrderType}}
      </el-descriptions-item>
      


      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          支付时间
        </template>
        {{ detail.payTime }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          订单总额
        </template>
        {{ detail.totalPrice }}元
      </el-descriptions-item>

      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          买家
        </template>
        {{ detail.purchaser }}
      </el-descriptions-item>

      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          买家备注
        </template>
        
        {{ detail.remark }}
      </el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">订单商品</el-divider>

    <el-descriptions :column="6" border labelClassName="desLabel" contentClassName="desContent"
      v-for="(item, index) in detail.orderGoodsDetails" :key="index">
      <!-- <el-descriptions-item>
     
            <img :src="item.image" />
        
          {{ item.name }}
        </el-descriptions-item> -->
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          商品
        </template>
        {{ item.name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          规格
        </template>
        {{ item.subheading }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          单位
        </template>
        {{ item.unit }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          数量
        </template>
        {{ item.count }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          单价
        </template>
        {{ item.price }}元
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          总价
        </template>
        {{ item.subtotal }}元
      </el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">收货人信息</el-divider>

    <el-descriptions :column="3" border :labelStyle="{ width: '100px' }">
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          收货人
        </template>
        {{ detail.consignee }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          收货手机
        </template>
        {{ detail.phone }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          收货地址
        </template>
        {{ detail.address }}
      </el-descriptions-item>
    </el-descriptions>

    <!-- <el-divider content-position="left">物流信息</el-divider> -->
    <!-- <el-descriptions :column="3" border :labelStyle="{ width: '100px' }">
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          配送方式
        </template>
        {{ detail.distributionMode | filterWay }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          物流公司
        </template>
        {{ logistics.expname }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          状态
        </template>
        {{ logistics.deliverystatus | filterStatus }}
        <div class="item"></div>
      </el-descriptions-item>
    </el-descriptions> -->

    <el-divider content-position="left">自提信息</el-divider>
    <el-descriptions :column="3" border :labelStyle="{ width: '100px' }">
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          自提点
        </template>
        {{ detail.selfLiftingName}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          自提地址
        </template>
        {{ detail.selfLiftingAddress }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          详细地址
        </template>
        {{ detail.selfLiftingUsernote  }}
        <div class="item"></div>
      </el-descriptions-item>
    </el-descriptions>
    <div class="p-20">
      <el-timeline>
        <el-timeline-item v-for="(item, index) in logistics.list" :key="index" :timestamp="item.time">
          {{ item.status }}
        </el-timeline-item>
      </el-timeline>
    </div>
    <div v-if="detail.distributionMode == 3">
      <el-divider content-position="left">跑腿信息</el-divider>
      <el-descriptions :column="4" border :labelStyle="{ width: '150px' }">
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            订单号
          </template>
          {{ detail.orderUupt.orderCode }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            状态
          </template>
          {{ filterUUStatus(detail.orderUupt.state) }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            发单时间
          </template>
          {{ detail.orderUupt.addTime }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            收货时间
          </template>
          {{ detail.orderUupt.finishTime }}
        </el-descriptions-item>


      </el-descriptions>

      <el-descriptions :column="4" border :labelStyle="{ width: '150px' }">
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            发货地址
          </template>
          {{ detail.orderUupt.fromAddress + detail.orderUupt.fromUsernote }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            收货地址
          </template>
          {{ detail.orderUupt.toAddress + detail.orderUupt.toUsernote }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            预约类型
          </template>
          {{ detail.orderUupt.subscribeType == 0 ? '实时订单' : '预约取件' }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            预约时间
          </template>
          {{ detail.orderUupt.subscribeTime }}
        </el-descriptions-item>

      </el-descriptions>


      <el-descriptions :column="4" border :labelStyle="{ width: '150px' }">
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            订单小类
          </template>
          {{ detail.orderUupt.sendType == 0 ? '帮我送' : '帮我买' }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            物品重量
          </template>
          {{ detail.orderUupt.goodsWeight }}KG
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            是否加急
          </template>
          {{ detail.orderUupt.urgentOrder == 0 ? '正常' : '加急' }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            总额（优惠前）
          </template>
          {{ detail.orderUupt.orderPrice }}
        </el-descriptions-item>

      </el-descriptions>

      <el-descriptions :column="4" border :labelStyle="{ width: '150px' }">
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            实际支付
          </template>
          {{ detail.orderUupt.balancePaymoney }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            收件人
          </template>
          {{ detail.orderUupt.receiver }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            收件人电话
          </template>
          {{ detail.orderUupt.receiverPhone }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            备注
          </template>
          {{ detail.orderUupt.note }}
        </el-descriptions-item>

      </el-descriptions>



      <el-descriptions :column="4" border :labelStyle="{ width: '150px' }">
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            跑手姓名
          </template>
          {{ detail.orderUupt.driverName }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            跑手电话
          </template>
          {{ detail.orderUupt.driverMobile }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            跑手工号
          </template>
          {{ detail.orderUupt.driverJobnum }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            预计送达时间
          </template>
          {{ detail.orderUupt.expectedarriveTime }}
        </el-descriptions-item>

      </el-descriptions>


      <el-descriptions :column="4" border :labelStyle="{ width: '150px' }">
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            当前状态说明
          </template>
          {{ detail.orderUupt.stateText }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            取消状态
          </template>
          {{ detail.orderUupt.cancelCode }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            取消原因
          </template>
          {{ detail.orderUupt.cancelReason }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            下单是否成功
          </template>
          {{ detail.orderUupt.addorderIsOk == 0 ? '成功' : '失败' }}
        </el-descriptions-item>

      </el-descriptions>

    </div>



  </div>
</template>

<script>
import api from "@/api/common";
export default {
  data() {
    // let self = this;
    return {
      dataList: [],

      detail: {},
      logistics: { list: [], expname: "", deliverystatus: "" },
    };
  },
  created() {
    this.getDataList();
  },
  watch: {
    // filtterData: {
    //   handler() {
    //     this.getList();
    //   },
    //   // immediate: true,
    //   deep: true, //开启深度监听
    // },
  },
  filters: {
    filterOrderStatus(type) {
  
      const statusMap = ["待支付", "待发货", "已发货", "已收货", "已取消"];
      return statusMap[type];
    },

    filterOrderType(type){
      const statusMap = ['正常下单','团长下单','团员下单'];
      return statusMap[type];
    },
    filterUUStatus(type) {

      if (!type) {

        type = -100
      }

      console.log('type', type);
      const statusMap = [
        { val: 1, label: '下单成功' },
        { val: 3, label: '跑男抢单' },
        { val: 4, label: '已到达' },
        { val: 5, label: '已取件' },
        { val: 6, label: '到达目的地' },
        { val: 10, label: '收件人已收货' },
        { val: -1, label: '订单取消' },
        { val: -100, label: '等待' },
      ];
      let index = statusMap.findIndex(item => {
        item.val == parseInt(type)
      })
      console.log('index', index);
      return statusMap[index]['label'];
    },

    filterStatus(type) {
      console.log(type);
      const statusMap = [
        "快递收件",
        "在途中",
        "正在派件",
        "已签收",
        "派送失败",
        "疑难件",
        "退件签收",
      ];
      return statusMap[type];
    },
    filterWay(val) {
      const statusMap = ["物流快递", "商家配送", "无需配送", "外卖"];

      return statusMap[val];
    },
  },
  mounted() {
    // this.getOrderLogisticsDetail();
  },

  methods: {



    filterUUStatus(type) {

      const statusMap = [
        { val: 1, label: '下单成功' },
        { val: 3, label: '跑男抢单' },
        { val: 4, label: '已到达' },
        { val: 5, label: '已取件' },
        { val: 6, label: '到达目的地' },
        { val: 10, label: '收件人已收货' },
        { val: -1, label: '订单取消' },
      ];

      let index = statusMap.findIndex(item => {
        return item.val == parseInt(type)
      })


      return statusMap[index]['label'];
    },
    jumpToList() {
      this.$router.push({ path: "/order/list" });
    },
    async getDataList() {
      try {
        let res = await api.commonGet({
          apiName: "/order/detail",
          id: this.$route.query.id,
        });

        this.detail = res.data;
        console.log('res.data', res.data);

        //在去查物流
      } catch (error) {
        console.log(error);
      }
    },

    async getOrderLogisticsDetail() {



      let res = await api.commonGet({
        apiName: "/order/logistics",
        orderId: this.$route.query.id,
      });
      if (res.code == 200) {
        this.logistics = res.data;
        this.logistics.list = JSON.parse(res.data.list);
      }
    },
    getSelection(e) {
      this.selecTionArr = e;
    },
    async onSizeChange(e) {
      this.pageInfo.pageSize = e;
      this.getDataList();
    },
    async onPageChange(e) {
      this.pageInfo.currentPage = e;
      this.getDataList();
    },
  },
};
</script>

<style lang="scss" >
.desLabel {
  width: 120px;
}

.desContent {
  width: 200px;
}
</style>
